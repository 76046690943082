<template>
  <div class="admin-page-wrapper">
    <v-row class="admin-header-nav">
      <admin-tabs />
      <v-spacer></v-spacer>
      <div class="header-action"></div>
    </v-row>
    <v-row class="server-users-subheader-nav pb-4">
      <div class="company-breadcrumb">
        <div class="breadcrumb-link mx-2">
          <v-btn icon to="/admin/servers"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
        </div>
        <div class="company-info">
          <v-list-item class="px-2">
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ server.toUpperCase() }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-subhead">
                Server Administration
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </v-row>
    <div class="server-users-table">
      <v-data-table
        :items="users"
        :search="search"
        :sort-desc="sortDesc"
        :loading="isLoading"
        loading-text="Loading... Please wait"
        :headers="headers"
        class="data-table__users"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import AdminTabs from "../AdminTabs.vue";
import { Constants } from "@/config/constants";

export default {
  components: {
    AdminTabs,
  },
  data() {
    return {
      users: [],
      server: "",
      search: "",
      sortDesc: false,
      headers: Constants.ServerUserTableHeaders,
      isLoading: true,
    };
  },
  mounted() {
    this.isLoading = false;
    const arr = this.$router.currentRoute.path.split("/");
    this.server = arr.pop();
  },
};
</script>

<style></style>
